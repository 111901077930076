<template>
  <v-row>
    <v-col class="mx-4">
      <br />
      <v-card dark>
        <v-card-text>
          <v-row>
            <v-col>
              <v-tabs grow>
                <v-tab>
                  <span>Informasi Event</span>
                  <v-icon right>mdi-calendar-range</v-icon>
                </v-tab>
                <v-tab>
                  <span>Informasi Hadiah</span>
                  <v-icon right>mdi-gift-outline</v-icon>
                </v-tab>
                <v-tab>
                  <span>Informasi Tantangan</span>
                  <v-icon right>mdi-bullseye-arrow</v-icon>
                </v-tab>

                <v-tab-item>
                  <v-card dark>
                    <v-row>
                      <v-col cols="4">
                        <EventForm />
                      </v-col>
                      <v-col>
                        <EventList />
                      </v-col>
                    </v-row>
                  </v-card>
                </v-tab-item>

                <v-tab-item>
                  <v-card dark>
                    <v-row>
                      <v-col cols="4">
                        <PrizeForm />
                      </v-col>
                      <v-col>
                        <PrizeList />
                      </v-col>
                    </v-row>
                  </v-card>
                </v-tab-item>

                <v-tab-item>
                  <v-card dark>
                    <v-row>
                      <v-col cols="4">
                        <ChallengeForm />
                      </v-col>
                      <v-col>
                        <ChallengeList />
                      </v-col>
                    </v-row>
                  </v-card>
                </v-tab-item>
              </v-tabs>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <br />
    </v-col>
  </v-row>
</template>

<script>
// import PrizeForm from '../../components/Prize/PrizeForm.vue';
export default {
  data() {
    return {};
  },
  components: {
    EventForm: () => import("../../components/Event/EventForm.vue"),
    EventList: () => import("../../components/Event/EventList.vue"),
    PrizeForm: () => import("../../components/Prize/PrizeForm.vue"),
    PrizeList: () => import("../../components/Prize/PrizeList.vue"),
    ChallengeForm: () => import("../../components/Challenge/ChallengeForm.vue"),
    ChallengeList: () => import("../../components/Challenge/ChallengeList.vue"),
  },
};
</script>